const cueSet1 = [
  {
    duration: 0.566037,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 0,
    time: 0,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000001,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 15360,
    time: 0.566037,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301849999999984,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 38400,
    time: 1.4150925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000001,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 46080,
    time: 1.698111,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000001,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 61440,
    time: 2.264148,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 76800,
    time: 2.830185,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301849999999984,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 99840,
    time: 3.6792405,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000001,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 107520,
    time: 3.962259,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 122880,
    time: 4.528296,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000004,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 138240,
    time: 5.094333,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301849999999984,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 161280,
    time: 5.9433885,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 168960,
    time: 6.226407,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000006,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 184320,
    time: 6.792444,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999995,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 199680,
    time: 7.358481,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 222720,
    time: 8.2075365,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 230400,
    time: 8.490555,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 245760,
    time: 9.056592,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000004,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 261120,
    time: 9.622629,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 284160,
    time: 10.4716845,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 291840,
    time: 10.754703000000001,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 307200,
    time: 11.32074,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000004,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 322560,
    time: 11.886777,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301849999999895,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 345600,
    time: 12.7358325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 353280,
    time: 13.018851,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 368640,
    time: 13.584888,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 384000,
    time: 14.150925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 407040,
    time: 14.9999805,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 414720,
    time: 15.282999,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999997,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 430080,
    time: 15.849036,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000022,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 445440,
    time: 16.415073,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 468480,
    time: 17.2641285,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 476160,
    time: 17.547147,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 491520,
    time: 18.113184,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 506880,
    time: 18.679221000000002,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 529920,
    time: 19.5282765,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 537600,
    time: 19.811295,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 552960,
    time: 20.377332,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 568320,
    time: 20.943369,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 591360,
    time: 21.7924245,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 599040,
    time: 22.075443,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 614400,
    time: 22.64148,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000022,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 629760,
    time: 23.207517,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 652800,
    time: 24.0565725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 660480,
    time: 24.339591000000002,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 675840,
    time: 24.905628,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 691200,
    time: 25.471665,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 714240,
    time: 26.3207205,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 721920,
    time: 26.603739,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 737280,
    time: 27.169776,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 752640,
    time: 27.735813,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 775680,
    time: 28.5848685,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 783360,
    time: 28.867887,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 798720,
    time: 29.433924,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000022,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 814080,
    time: 29.999961,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.28301850000000073,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 837120,
    time: 30.8490165,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 844800,
    time: 31.132035000000002,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999979,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 860160,
    time: 31.698072,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000057,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 875520,
    time: 32.264109,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 898560,
    time: 33.1131645,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 906240,
    time: 33.396183,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 921600,
    time: 33.96222,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 936960,
    time: 34.528257,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 960000,
    time: 35.3773125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 967680,
    time: 35.660331,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 983040,
    time: 36.226368,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 998400,
    time: 36.792405,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1021440,
    time: 37.6414605,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1029120,
    time: 37.924479,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1044480,
    time: 38.490516,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1059840,
    time: 39.056553,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830185000000043,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1082880,
    time: 39.9056085,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999944,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1090560,
    time: 40.188627000000004,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1105920,
    time: 40.754664,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1121280,
    time: 41.320701,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830185000000043,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1144320,
    time: 42.1697565,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1152000,
    time: 42.452775,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999944,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1167360,
    time: 43.018812000000004,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000057,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1182720,
    time: 43.584849,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1205760,
    time: 44.433904500000004,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1213440,
    time: 44.716923,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1228800,
    time: 45.28296,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1244160,
    time: 45.848997000000004,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1267200,
    time: 46.6980525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1274880,
    time: 46.981071,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1290240,
    time: 47.547108,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1305600,
    time: 48.113145,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1328640,
    time: 48.9622005,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1336320,
    time: 49.245219,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1351680,
    time: 49.811256,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1367040,
    time: 50.377293,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830185000000043,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1390080,
    time: 51.2263485,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999944,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1397760,
    time: 51.509367000000005,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1413120,
    time: 52.075404,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1428480,
    time: 52.641441,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830185000000043,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1451520,
    time: 53.4904965,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999944,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1459200,
    time: 53.773515,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1474560,
    time: 54.339552,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490554999999986,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1489920,
    time: 54.905589,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830185000000043,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1512960,
    time: 55.7546445,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1520640,
    time: 56.037663,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660369999999944,
    durationTicks: 15360,
    midi: 36,
    name: "C2",
    ticks: 1536000,
    time: 56.6037,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.8490555000000057,
    durationTicks: 23040,
    midi: 37,
    name: "C#2",
    ticks: 1551360,
    time: 57.169737,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.2830184999999972,
    durationTicks: 7680,
    midi: 36,
    name: "C2",
    ticks: 1574400,
    time: 58.0187925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.5660370000000015,
    durationTicks: 15360,
    midi: 37,
    name: "C#2",
    ticks: 1582080,
    time: 58.301811,
    velocity: 0.6299212598425197,
  },
];

export default cueSet1;
